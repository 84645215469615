export enum BattleDemoFragmentType {
  Solar = 'solar',
  Lunar = 'lunar'
}

export enum BattleDemoPlayMode {
  Speedrun = 'speedrun',
  Maxrooms = 'maxrooms',
  Unknown = 'unknown'
}

export enum BattleDemoRewardSetType {
  Animus = 'Animus',
  DoodItem = 'DoodItems'
}

export const REWARD_BACKGROUND_COLORS = [
  '#350267',
  '#4d0596',
  '#5908AB',
  '#5c06b2',
  '#5e0dae',
  '#6A21B3',
  '#702db3',
  '#7339AD',
  '#8b3cf2',
  '#9747FF'
]

export const BURN_MAX_LIMIT = 99
export const BURN_MIN_LIMIT = 1
export const FRAGMENT_COUNT_LIMIT = 999
export const CURRENT_BATTLE_DEMO_SEASON = 1
export const TOP_3_TOOLTIP_ID = 'top_3_tooltip'
export const TOTAL_TOOLTIP_ID = 'total_tooltip'
export const REWARD_TOOLTIP_ID = 'reward_tooltip'
export const FURTHEST_ROOM_TOOLTIP_ID = 'furthest_room_tooltip'
export const PLANET_CLASS_TOOLTIP_ID = 'planet_class_tooltip'
export const ENTERED_BATTLE_DEMO = 'entered_battle_demo'
export const GUILD_WAR_LEADERBOARD_PER_PAGE = 20
export const GUILD_WAR_LEADERBOARD_NO_VALUE = -1
export const GUILD_PER_PAGE = 9
