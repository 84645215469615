import SharedSecNavBar from '@src/components/navigationBar/secondary/SharedSecNavBar'
import { FeatureFlagType } from '@src/constants/featureFlag'
import { NAV_BAR_CONFIG } from '@src/constants/layout'
import { ROUTE } from '@src/constants/route'
import featureAllowed from '@src/util/apeiron/featureAllowed'
import { FC, useMemo } from 'react'

const ALLOW_NEWS = featureAllowed(FeatureFlagType.ShowBattleDemoNews)

const ALLOW_GUILD_PVE_LEADERBOARD = featureAllowed(
  FeatureFlagType.ShowBattleDemoGuildPvELeaderboard
)

export const NAVIGATION_ITEMS = [
  {
    route: ROUTE.BATTLE_DEMO_GAME,
    translationKey: 'sub_bar.battle_demo.game'
  },
  {
    route: ROUTE.BATTLE_DEMO_BEGINNERS_GUIDE,
    translationKey: 'sub_bar.battle_demo.beginners_guide'
  },
  ...(ALLOW_NEWS
    ? [
        {
          route: ROUTE.BATTLE_DEMO_NEWS,
          translationKey: 'sub_bar.battle_demo.news'
        }
      ]
    : []),
  {
    route: ROUTE.BATTLE_DEMO_BATTLE_PASS,
    translationKey: 'sub_bar.battle_demo.pass'
  },
  {
    route: ROUTE.BATTLE_DEMO_ECONOMY,
    translationKey: 'sub_bar.battle_demo.economy'
  },
  {
    route: ROUTE.BATTLE_DEMO_SOLO_PVE,
    translationKey: 'sub_bar.battle_demo.leaderboard'
  },
  ...(ALLOW_GUILD_PVE_LEADERBOARD
    ? [
        {
          route: ROUTE.BATTLE_DEMO_GUILD_WAR_PVE,
          translationKey: 'sub_bar.battle_demo.guild_war_pve'
        }
      ]
    : []),
  {
    route: ROUTE.BATTLE_DEMO_GUILD_WAR_PVP,
    translationKey: ALLOW_GUILD_PVE_LEADERBOARD
      ? 'sub_bar.battle_demo.guild_war_pvp'
      : 'sub_bar.battle_demo.guild_war'
  }
]

const NavigationBar: FC<Props> = (props: Props) => {
  const { route } = props

  const index = useMemo(() => {
    return NAVIGATION_ITEMS.findIndex(item => item.route === route)
  }, [route])

  return (
    <SharedSecNavBar
      height={NAV_BAR_CONFIG.secondary.height}
      index={index}
      navigationItems={NAVIGATION_ITEMS}
    />
  )
}

type Props = {
  route: ROUTE
}

export default NavigationBar
