import { DownloadMap, MayNull, PageInfoLite } from '@apeiron/library'
import {
  BattleDemoFragmentType,
  BattleDemoPlayMode,
  BattleDemoRewardSetType
} from '@src/constants/battleDemo'
import { DropdownItem } from '@src/types/filter'
import { Guild } from '@src/types/guild'
import { PlanetClass } from '@src/types/planet'

export type BattleDemoNews = {
  date: string
  content: string
  thumbnail?: string
  banner?: string
  title: string
  description: string
}

export type BattleDemoNewsList = {
  news: BattleDemoNews[]
  patches: BattleDemoNews[]
}

export type BattleDemoConfig = {
  downloadMap: DownloadMap
  news: BattleDemoNewsList
}

// fragment to burn demo playtime
export type BattleDemoFragment = {
  burn: number
  burnRatio: number
  id: string
  name: string
  openSeaUrl: string
  total: number
  type: BattleDemoFragmentType
}

export type BattleDemoSeason = DropdownItem & {
  active: boolean
  rewardGroups: MayNull<BattleDemoRewardGroup[]>
}

export type BattleDemoRankPlanet = {
  icon: string
  id: string
  planetClass: PlanetClass
}

export type BattleDemoRankUser = {
  name: string
  tag: number
  walletAddress: string
}

export type BattleDemoRank = {
  id: string
  planet: BattleDemoRankPlanet
  rank: number
  user: BattleDemoRankUser
  score: number
}

export type BattleDemoLeaderboardSummary = {
  end: string
  name: string
  offSeason: boolean
  playMode: BattleDemoPlayMode
  season: string
  start: string
}

export type BattleDemoLeaderboardRecord = {
  pageInfo: PageInfoLite
  playerRank?: MayNull<BattleDemoRank>
  ranks: BattleDemoRank[]
}

export type BattleDemoLeaderboard = {
  summary: BattleDemoLeaderboardSummary
  record: BattleDemoLeaderboardRecord
  rewardGroups: BattleDemoRewardGroup[]
}

export type BattleDemoRewardTier = {
  bonus: string
  rank: number
  reward: string
}

export type BattleDemoRewardLite = {
  name: string
  value: number
}

export type BattleDemoRewardGroup = {
  rankMax: number
  rankMin: number
  rewardsBasic: BattleDemoRewardLite[]
  rewardsBonus: BattleDemoRewardLite[]
}

export type BattleDemoGuildPvERank = BattleDemoGuildRank & {
  clearRoom: number // best 3
  clearTime: number // best 3
  clearTotalRoom: number // guild total
}

export type BattleDemoGuildPvPRank = BattleDemoGuildRank & {
  matchMakingRate: number // best 3
  totalStar: number
}

export type BattleDemoGuildRank = {
  guild: Guild
  id: string
  rank: number
}

export type BattleDemoGuildLeaderboard<
  T extends BattleDemoGuildPvERank | BattleDemoGuildPvPRank
> = {
  myRank?: MayNull<T>
  name: string
  ranks: T[]
  rewardGroups: BattleDemoRewardGroup
}

export type BattleDemoAboutCard = {
  description: string
  image: string
  title: string
  imageAspectRatio: number
}

export type BattleDemoAbout = {
  apeironTitle: string
  content: BattleDemoAboutCard[]
  title: string
}

export type BattleDemoReward = BattleDemoRewardLite & {
  image: string
  claimDatatype: ClaimableCurrencyDatatype
  source: string
}

export type BattleDemoRewardSet = {
  items: BattleDemoReward[]
  type: BattleDemoRewardSetType
}

export type BattleDemoRewardSetPool = {
  [key in BattleDemoRewardSetType]?: BattleDemoReward[]
}

// game server currencies
export enum ClaimableCurrencyDatatype {
  Aprm = 'Aprm',
  Animus = 'Animus',
  DoodHalo = 'Dood_Halo',
  DoodelicWings = 'Doodelic_Wings'
}
