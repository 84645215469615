import { Box, styled, Typography } from '@mui/material'
import * as RA from 'ramda-adjunct'
import { FC } from 'react'

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  ${props => props.theme.breakpoints.up('res1024')} {
    gap: 8px;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    gap: 12px;
  }
`

const ApeironTitle = styled(Typography)`
  color: #b2b2b2;
  font-family: var(--font-Apeiron);
  line-height: 0px;
  ${props => props.theme.breakpoints.up('res1024')} {
    font-size: 30px;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    font-size: 20px;
  }
`

const Title = styled(Typography)`
  color: white;
  font-weight: 700;
  ${props => props.theme.breakpoints.up('res1024')} {
    font-size: 56px;
    line-height: 56px;
  }
  ${props => props.theme.breakpoints.down('res1024')} {
    font-size: 30px;
    line-height: 30px;
  }
`

const Subtitle = styled(Typography)`
  color: white;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin-top: -10px;
`

const BattleDemoTitle: FC<Props> = (props: Props) => {
  const { apeironTitle, className, subtitle = '', title } = props

  return (
    <Container className={className}>
      <ApeironTitle className='Custom-Apeiron-Title'>
        {apeironTitle || title}
      </ApeironTitle>
      <Title className='Custom-Title'>{title}</Title>
      {RA.isNotNilOrEmpty(subtitle) && (
        <Subtitle className='Custom-Subtitle'>{subtitle}</Subtitle>
      )}
    </Container>
  )
}

type Props = {
  apeironTitle?: string
  className?: string
  subtitle?: string
  title: string
}
export default BattleDemoTitle
