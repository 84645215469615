import { Guild } from '@src/types/guild'
import * as yup from 'yup'

export const guildSchema: yup.SchemaOf<Guild> = yup
  .object({
    icon: yup.string().default(''),
    id: yup.string().default(''),
    name: yup.string().default(''),
    tag: yup.number().default(0)
  })
  .from('iconURL', 'icon')

export const changeGuildSchema: yup.BaseSchema<boolean> = yup
  .boolean()
  .default(false)
