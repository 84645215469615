import {
  DownloadInfo,
  downloadInfoSchema,
  DownloadMap,
  FetchJsonProps,
  useFetchJson,
  useReactQueryFetchJson
} from '@apeiron/library'
import ENV_CONFIG from '@src/config'
import { battleDemoNewsSchema } from '@src/deserialize/yup/battleDemo'
import { BattleDemoConfig, BattleDemoNewsList } from '@src/types/battleDemo'
import * as R from 'ramda'

const useFetchBattleDemoConfigJson = (props?: FetchJsonProps) => {
  const { fetchNow } = props || {}

  const { fetchJson: fetchBuildInfo } = useFetchJson({
    url: ENV_CONFIG.JSON_URL.BATTLE_DEMO_CONFIG
  })

  return useReactQueryFetchJson<BattleDemoConfig>({
    fetchNow,
    path: ENV_CONFIG.JSON_FILE_NAME.DOWNLOAD,
    deserialize: async response => {
      const config = (await fetchBuildInfo()) as Object[]

      const validate = (data: Object): DownloadInfo => {
        return downloadInfoSchema.cast(data, {
          context: {
            data,
            info: R.propOr({}, 'client_version', config)
          }
        }) as DownloadInfo
      }

      const downloadMap: DownloadMap = {
        battleDemo: R.map(validate, response.battleDemo || []),
        foonieMagus: R.map(validate, response.battleDemo || []),
        login: R.map(validate, response.login || []),
        officialSite: R.map(validate, response.officialSite || [])
      }

      const news = battleDemoNewsSchema.validateSync(
        R.propOr({}, 'news', config)
      ) as BattleDemoNewsList

      return { downloadMap, news }
    }
  })
}

export default useFetchBattleDemoConfigJson
